<template>
  <div id="vm-cola" :class="route_name">
    <Header v-if="!['vmColaHome', 'vmColaProducts'].includes(route_name)" :class-name="route_name" />

    <router-view></router-view>

    <Footer
      v-if="!['vmColaHome', 'vmColaProducts', 'vmColaOrder'].includes(route_name)"
      :position="setRouteClassName()"
      :class-name="route_name"
    />

    <ModalQuickView v-if="isShowModalQuickView" key="ModalQuickView" />
    <ModalNotificationAddCart key="ModalNotificationAddCart" />
    <GlobalLoading v-if="global_loading" />
  </div>
</template>

<script>
import IdleVue from 'idle-vue';
import helpers from '@/mixins/helpers';
import ls from '@/components/helpers/local-storage';
import { get } from 'lodash';

import { userActions } from '@/helpers/user-actions';

const Config = require('~/config/default.env').default;

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 600000,
  startAtIdle: false,
});

export default {
  name: 'vmColaIndex',
  components: {
    Header: () => import('@/components/vm-cola/snippets/header/header'),
    Footer: () => import('@/components/vm-cola/snippets/footer/footer'),
    GlobalLoading: () => import('@/components/vm-cola/snippets/global-loading'),
    ModalQuickView: () => import('@/components/vm-cola/snippets/modal/modal-quick-view'),
    ModalNotificationAddCart: () => import('@/components/vm-cola/snippets/modal/modal-notification-add-cart'),
  },
  mixins: [helpers],
  computed: {
    global_loading() {
      return this.$store.state.vmCola.global_loading;
    },
    route_name() {
      return this.$route.name;
    },
    isShowModalQuickView() {
      return this.$store.state.vmCola.quickView.isShow;
    },
    isOrderCreated() {
      return this.$store.state.QRcode.is_order_created;
    },
  },
  watch: {
    $route(to, from) {
      this.setRouteClassName();
      if (to.name === 'vmColaHome') {
        this.$store.dispatch('getVmColaBanner', this);
      }

      if (from && from.name === 'vmColaOffline') {
        location.reload(true);
      }
    },
  },
  beforeDestroy() {
    socket.off('vending_machine_action');
  },

  created() {
    this.$store.dispatch('getVmColaBanner', this);
    if (
      (!Cookies.get('location_detail_vm_cola') && Cookies.get('location_detail_vm_cola') !== 'undefined') ||
      Cookies.get('location_detail_vm_cola') === 'undefined'
    ) {
      this.$router.push('/');
    }
    this.setRouteClassName();
    if (localStorage.getItem('cart')) {
      this.$store.dispatch('setNoLoginCart');
    }

    this.checkStartupMachine();
    userActions(this);

    this.checkConnections();
  },

  methods: {
    setRouteClassName() {
      const routeName = this.route_name;
      let res = '';
      if (routeName) {
        if (['vmColaHome', 'vmColaHomeV2'].includes(routeName)) {
          res = 'home';
        } else if (routeName === 'vmColaProducts') {
          res = 'products';
        } else if (routeName === 'vmColaOrder') {
          res = 'order';
        } else if (
          ['vmColaPayment', 'vmColaPaymentSuccess', 'vmColaPaymentSuccessFailed', 'vmColaPaymentFailed'].includes(
            routeName
          )
        ) {
          res = 'payment';
        } else if (['vendingMachineLogin', 'vmColaLogin'].includes(routeName)) {
          res = 'login';
        } else {
          res = routeName;
        }
      }
      return res;
    },
    checkConnections() {
      const context = this;

      window.addEventListener('offline', async () => {
        await Promise.all([
          ls.remove('cart'),
          ls.remove('latest_cart_id'),
          context.$store.commit('SET_CART_ID', null),
          context.$store.commit('footer_cart', []),
          context.$store.commit('cart_qty', 0),
          context.$store.commit('SET_ALREADY_SEND_CART', false),
          context.$store.commit('SET_SELECTED_POINT', 0),
        ]);

        context.$router.push('/vmcola/offline');
      });

      window.addEventListener('online', () => {
        context.$router.push('/vmcola');
      });
    },
    async checkStartupMachine() {
      const store_detail = await Cookies.get('store_detail');
      const vm_alias = this.$route.query.vm_alias;

      if (this.$route.name == 'vmColaHome' && vm_alias && !store_detail) {
        const detail = await this.fetchVendingMachine(vm_alias);
        if (detail) {
          Cookies.set('store_detail', detail);

          setTimeout(() => {
            location.reload(true);
          }, 2000);
        }
      }
    },
    fetchVendingMachine(alias) {
      return axios
        .get(`${Config.MS_CATALOG_API_URL}/vending-machines`, {
          params: {
            filter: {
              alias: alias,
            },
            sort: '-created_at',
            fields: 'id,name,alias,address,status,store_id',
          },
          headers: {
            'SOC-PLATFORM': 'vending-machine',
          },
        })
        .then((res) => {
          const detail = get(res, 'data.data[0]', null);
          return detail;
        })
        .catch((err) => {
          this.$toasted.global.error('Something went wrong!');
          return null;
        });
    },
  },
  async onIdle() {
    this.$store.commit('HIDE_MODAL', 'quickView');
    await Promise.all([
      localStorage.removeItem('guest_cart_id'),
      localStorage.removeItem('latest_cart_id'),
      localStorage.removeItem('is_guest'),
      localStorage.removeItem('cart'),
    ]);

    if (this.$route.name == 'vmColaHome') {
      return;
    }

    this.cancelOrder(true, '/vmcola', false, this.isOrderCreated);
  },
};
</script>
